// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBNtfnORfx40DDw90Xd_pBucMVB1ApjPHc",
    authDomain: "trublu-fae5a.firebaseapp.com",
    projectId: "trublu-fae5a",
    storageBucket: "trublu-fae5a.appspot.com",
    messagingSenderId: "875820028369",
    appId: "1:875820028369:web:6e552851b2bf5d1a4b90f0",
    measurementId: "G-EDTYNVX2VT"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
